import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import './Layout.css'

function Layout({sections}) {
  return (
    <div className='Layout'>
        <Header sections={sections}/>
        <Outlet />
        <Footer />
    </div>
  );
}

export default Layout;