import { i18n, Messages } from "@lingui/core"

export const locales = {
    lv: "Latviski",
    en: "English",
}
export const defaultLocale = "lv"

const catalogs: Record<string, () => Promise<Messages>> = {
  lv: async () => {
    const { messages } = await import(
      // @ts-ignore
      `./locales/lv/messages.js`
    )
    return messages
  },
  en: async () => {
    const { messages } = await import(
      // @ts-ignore
      `./locales/en/messages.js`
    )
    return messages
  },
}
/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  const messages = await catalogs[locale as any]()
  i18n.loadAndActivate({ locale, messages })
}