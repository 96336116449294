import React from 'react';
import './Logo.css';

function Logo() {
  return (
    <div className="Logo">
        <a href='/'>
            <h2 className='Logo1'>LS</h2>
            <h2 className='Logo2'>M</h2>
            <h2 className='Logo1'>AT 2024</h2>
        </a>
        {/* <img src="./logo1.jpeg" alt="Company Logo"/> */}
    </div>
  );
}

export default Logo;
