import React from 'react';
import { useEffect } from "react"
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Trans } from "@lingui/macro";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { defaultLocale, dynamicActivate } from "./i18n"
import * as ReactDOMClient from "react-dom/client"

//const myInitObject = {}
//export default myInitObject

const I18nApp = () => {
  useEffect(() => {
    dynamicActivate(defaultLocale)
  }, [])

  return (
    <I18nProvider i18n={i18n}>
      <App />
    </I18nProvider>
  )
}

ReactDOMClient.createRoot(document.getElementById("root") as Element).render(
  <React.StrictMode>
    <I18nApp />
  </React.StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
