import React, { useState, useTransition } from 'react';
import { Trans, t } from "@lingui/macro";
import { CiMenuBurger } from "react-icons/ci";
import Logo from '../Logo'
import './Header.css';
import ActionButton from '../ActionButton'

function DesktopNav({ sections }) {
  return (
    <nav className='DesktopNav'>
      <ul>
          <>
            {sections.map(section => (
              <li><a href={section.href} className='NavEntry'>{section.name}</a></li>
            ))}
          </>
          <ActionButton title='Reģistrējies' href='/signup'/>
      </ul>
  </nav>
  );
}

function MobileNav({ sections }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='MobileNav'>
      <CiMenuBurger onClick={() => setIsOpen(!isOpen)} className='BurgerMenu' size={60}/>
      {isOpen && (
        <div className='overlay' onClick={() => setIsOpen(!isOpen)}>
          <ul className='MobileMenu'>
            <>
              {sections.map(section => (
                <li><a href={section.href}>{section.name}</a></li>
              ))}
            </>
            <ActionButton title='Reģistrējies' href='/signup'/>
          </ul>
        </div>
      )}
    </div>
  );
}

function Header({sections}) {
  return (
    <header className="Header">
        <div className='HeaderList'>
          <Logo/>
          <DesktopNav sections={sections}/>
          <MobileNav sections={sections}/>
        </div>
        <hr className='DivLine'/>
    </header>
  );
}

export default Header;
