import React from 'react';
import { Trans } from "@lingui/macro";
import './About.css';

function About() {
  return (
    <div className='About'>
        <div className='MainInfo'>
            <Trans><p>&emsp;Matemātiskā analīze ir matemātikas nozare bez kuras nevar iztikt, risinot praktiskas problēmas
            fizikā, inženierijā, programmēšanā un ekonomikā, taču vidusskolas kursā tai netiek pievērsta
            pietiekama uzmanība un matemātikas olimpiādēs tā vispār netiek pieminēta. Mēs vēlamies
            popularizēt šo matemātikas pusi organizējot aizraujošu konkursu, kas ne tikai cels skolēnu
            zināšanu līmeni, bet arī būs jautrs.
            </p></Trans>
            <Trans><p>Ideja par matemātiskās analīzes turnīru ir aizgūta no Masačūsetsas Tehnoloģiju institūta integrēšanas konkursa (MIT Integration Bee), kas ir norisinājies 43 gadus.
                 Dažādos formātos matemātiskās analīzes sacensības tiek organizētas visā pasaulē.
                </p></Trans>
            <Trans><p>&emsp;Turnīru organizē komanda ar skolēniem un absolventiem no RTU Inženierzinātņu vidusskolas un Rīgas Valsts 1. ģimnāzijas.
                </p></Trans>
            <Trans><h2>Kontakti:</h2></Trans>
            <p><Trans>E-pasts: </Trans><a href="mailto:info@analizesturnirs.lv">info@analizesturnirs.lv</a></p>
            <p className='ContactDets'><Trans>Instagrams: </Trans><a href='https://www.instagram.com/analizesturnirs?igsh=MXVvMjFzcjVoZnoxZQ=='>@analizesturnirs</a></p>
        </div>
        <figure className='AboutImg'>
          <img src='/analizesturnirs-2.jpg'/>
          <figcaption>Foto autors: Rūdolfs Žihars</figcaption>
        </figure>
    </div>
  );
}

export default About;