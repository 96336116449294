
import React from 'react';
import { Trans } from "@lingui/macro";
import './ActionButton.css';

interface ButtonProps {
    title: string;
    href: string;
  }

function ActionButton({ title, href }: ButtonProps) {
  return (
    <div className='ActionButton'>
        <a href={href}>
            <button className='InnerButton'>{title}</button>
        </a>
    </div>
  );
}

export default ActionButton;