import React from 'react';
import { Trans } from "@lingui/macro";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import './Footer.css';

function Footer() {
  return (
    <footer className='Footer'>
        <hr className='DivLine'/>
          <div className='FooterCategories'>
          <div className='Socials'>
            <a className='SocialLabel'
            href='https://www.instagram.com/analizesturnirs?igsh=MXVvMjFzcjVoZnoxZQ=='>
                <FaInstagram size={50}/>
                <p>analizesturnirs</p>
            </a>
            <a  className='SocialLabel'
              href="mailto:info@analizesturnirs.lv">
                <MdOutlineEmail size={50}/>
                <span>info@analizesturnirs.lv</span>
            </a>
          </div>
          <img src="sponsors.png" alt="" className='SponsorImg'/>
        </div>
        {/*
        <div className='Sponsors'>
          <img src='./lmsa.jpg'/>
          <img src='./LU.png'/>
        </div>
        */}
    </footer>
  );
}

export default Footer;