import React from 'react';
import { motion, useScroll, useTransform } from "framer-motion"
import { Trans, t } from "@lingui/macro";
import './Landing.css';
import TimelineEntry from './TimelineEntry';
import ActionButton from './ActionButton'

function Landing() {
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 30]);

  return (
    <div className="Landing">
      <div className='IntroCont'>
        <div className='IntroGrid' />
        <motion.div className="Intro" style={{ scale: scale, originX: 0.59529, originY: 0.8307  }} >
          <Trans><h1>Matemātiskā analīze padara bezgalīgi mazo redzamu</h1></Trans>
          <h1>.</h1>
        </motion.div>
      </div>
      
      <div className='Timeline'>
        <div className='TimelineEntry'>
          <Trans><h2>Pieteikšanās</h2></Trans>
          <Trans><h3>No 30. oktobra</h3></Trans>
          <Trans><p>
          Pieteikties ir aicināts ikviens vidusskolas skolēns ar interesi par matemātisko analīzi. Sagatavošanās sadaļā var atrast <a href = '/LSMAT_Teorija.pdf' target = "_blank">teorijas materiālus</a>, lai pilnvērtīgi apgūtu turnīrā iekļautās tēmas.
          </p></Trans>
          <ActionButton title='Reģistrējies turnīram' href='/signup'/>
        </div>
        <div className='TimelineEntry'>
          <Trans><h2>Kursi</h2></Trans>
          <Trans><h3>No 3. novembra</h3></Trans>
          <Trans><p>
          Šogad skolēniem ir unikāla iespēja apmeklēt bezmaksas matemātiskās analīzes ievada kursus, lai turnīram sagatavotos pat bez priekšzināšanām. Kursi notiks 5 nedēļas nogales: 3., 10., 17., 23. novembrī un 1. decembrī no rītiem, Rīgas Valsts 1. ģimnāzijas telpās. Programma tiek īstenota sadarbībā ar Rīgas Domi, tas ir <strong>tikai Rīgas skolēniem</strong>.
          </p></Trans>
          <ActionButton title='Reģistrējies kursiem' href='/course-signup'/>
        </div>
        <div className='TimelineEntry'>
          <Trans><h2>Atlase</h2></Trans>
          <Trans><h3>4. decembris</h3></Trans>
          <Trans><p>
          Pirmā kārta norisināsies vienlaicīgi vairākās skolās - katrs dalīnieks saņems uzdevumu komplektu no kura ir jāmēģina izrisināt pēc iespējas vairāk ierobežotā laikā. 
          </p></Trans>
          <Trans><p>Aicinām iepazīties ar <a href='/LSMAT_Nolikums.pdf'>turnīra nolikumu!</a></p></Trans>
        </div>
        <div className='TimelineEntry'>
          <Trans><h2>Fināls</h2></Trans>
          <Trans><h3>18. decembris</h3></Trans>
          <Trans><p>
          32 dalībnieki ar vislabākajiem rezultātiem atlasē tiek uzaicināti uz kopīgu pasākumu Latvijas Universitātes Zinātņu mājā (Jelgavas ielā 3, Rīgā). Fināls norisinās izslēgšanas turnīra formātā - piemērus risina viens pret vienu uz krīta tāfeles skatītāju priekšā.
          </p></Trans>
        </div>
      </div>
    </div>
  );
}

export default Landing;
